body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-stars{
  display: flex;
}

.feedback-grid::-webkit-scrollbar {
  height: 0px !important;
}

.feedback-grid::-webkit-scrollbar-thumb {
  border: 3px solid transparent !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  background-clip: content-box !important;
}

.feedback-grid::-webkit-scrollbar-thumb:hover {
  background-color: transparent !important;
}

.wrapper-class{
  background: #242A34;
  border: 1px solid;
  border-color: #96A090;
  min-height: 400px;
  border-radius: 1rem;
  overflow: hidden;
}
.editor-class{
  color: #EAE5D8;
  font-weight: lighter;
  height: 100%;
}
.toolbar-class{
  border-top: 1rem;
}